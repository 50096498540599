

html{
  width: 100%;
  height:100%;
  background: linear-gradient(-45deg,#fee5ce, #ffebd2, #265d8c);
  overflow: hidden;
  /*overflow-y: hidden;*/
}

body {
  width: 100%;
  height:100%;
  background: linear-gradient(-45deg,#fee5ce, #ffebd2, #265d8c);
  overflow: hidden;  
}

@keyframes translateOverlay  {
  to  {
    transform: translateX(101%);
  }
}


.App {
  text-align: center;
/*  overflow: hidden;*/
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/*MUI styles*/

.MuiTabs-indicator {
  background-color: #008cb7 !important;
}

.MuiInputBase-input{
  padding: 2px 4px 4px 4px !important;
}

.MuiInput-underline:after, .MuiInput-underline:before{

  /*border-bottom: 1px solid #008cb7 !important;*/
  border-bottom: 0px !important;
  transform: none !important;
}
