#cube{
  width: 50%;
  height: 50%;
  transform-style: preserve-3d;
  animation: rotateCube 0.8s 1;
}
#cube > div{
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 1;
}

@keyframes rotateCube{
  0% { transform: rotateX(50deg) rotateY(315deg) rotateZ(90deg); }
  100% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) ; }
}

#cube #cubeFront{
  transform: rotateX(-15deg) rotateY(45deg) translateZ(50px);
  background: #ffebd2;
  border-radius: 5px;
}
#cube #cubeBack{
  transform: rotateX(-15deg) rotateY(45deg) translateZ(-50px) scaleX(-1);
  background: #ffebd2;
  border-radius: 5px;
}
#cube #cubeRight{
  transform: rotateX(-15deg) rotateY(-45deg) translateZ(50px);
  background: #ffebd2;
  border-radius: 5px;
}
#cube #cubeLeft{
  transform: rotateX(-15deg) rotateY(-45deg) translateZ(-50px) scaleX(-1);
  background: #ffebd2;
  border-radius: 5px;
}
#cube #cubeTop{
  transform: rotateX(-15deg) rotateY(45deg) rotateX(90deg)  translateZ(50px);
  background: #ffebd2;
  border-radius: 5px;
}
#cube #cubeBottom{
  transform: rotateX(-15deg) rotateY(45deg) rotateX(90deg) translateZ(-50px) scaleX(-1);
  background: #ffebd2;
  border-radius: 5px;
}
