/*Document Reader*/
.jodit-ui-group:first-child, .jodit-ui-group:last-child, .jodit-status-bar:last-child{
  background: #ffebd2 !important;  
}
.jodit-toolbar__box:first-child{
  border-bottom: 1px solid #ffebd2;  
}
.jodit-status-bar-link, .jodit-toolbar-button_selectall:first-child, .jodit-status-bar__item > .jodit-xpath {
  display: none;  
}
.jodit-status-bar, .jodit-status-bar:hover{
  background-color: #ffebd2 !important;  
}
.jodit-toolbar-button:hover > *{
  /*background: #fee5ce !important;  */
  background: #f5e2ca !important;
}
.jodit-toolbar-button:active > *{  
  /*background: #f5a33e !important;  */
  background: #b6a896 !important;
}

/*.jodit-icon_table {
  filter: drop-shadow(8px 8px 10px red) invert(48%) sepia(19%) saturate(2476%) hue-rotate(166deg) brightness(100%) contrast(100%) !important
}*/
.jodit-icon_cut {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(166deg) brightness(120%) contrast(100%)}
.jodit-icon_copy {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(3deg) brightness(150%) contrast(100%)}
.jodit-icon_paste {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(12deg) brightness(100%) contrast(100%)}
.jodit-icon_undo {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(180deg) brightness(100%) contrast(100%)}
.jodit-icon_redo {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(180deg) brightness(100%) contrast(100%)}

.jodit-icon_paragraph {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(200deg) brightness(0%) contrast(150%)}
.jodit-icon_font {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(280deg) brightness(100%) contrast(200%)}
.jodit-icon_fontsize {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(300deg) brightness(100%) contrast(200%)}

.jodit-icon_bold {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(400deg) brightness(70%) contrast(150%)}
.jodit-icon_italic {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(400deg) brightness(70%) contrast(150%)}
.jodit-icon_underline {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(400deg) brightness(70%) contrast(150%)}
.jodit-icon_strikethrough {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(400deg) brightness(70%) contrast(150%)}
.jodit-icon_superscript {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(500deg) brightness(70%) contrast(150%)}
.jodit-icon_subscript {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(500deg) brightness(70%) contrast(150%)}

.jodit-icon_left {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(20deg) brightness(60%) contrast(50%)}
.jodit-icon_center {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(20deg) brightness(60%) contrast(50%)}
.jodit-icon_right {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(20deg) brightness(60%) contrast(50%)}
.jodit-icon_justify {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(20deg) brightness(60%) contrast(50%)}

.jodit-icon_ul {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(20deg) brightness(20%) contrast(30%)}
.jodit-icon_ol {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(20deg) brightness(20%) contrast(30%)}

.jodit-icon_outdent {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(890deg) brightness(100%) contrast(150%)}
.jodit-icon_indent {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(890deg) brightness(100%) contrast(150%)}

/*'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript',*/
/*'align', '|', 'ul', 'ol', '|', 'outdent', 'indent',*/

.jodit-icon_table {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(166deg) brightness(100%) contrast(100%)}

.jodit-icon_symbols {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(350deg) brightness(100%) contrast(150%)}
.jodit-icon_hr {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(0deg) brightness(0%) contrast(150%)}

.jodit-icon_search {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(190deg) brightness(110%) contrast(200%)}
.jodit-icon_print {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(180deg) brightness(80%) contrast(200%)}
    
.jodit-icon_source {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(120deg) brightness(100%) contrast(100%)}
.jodit-icon_fullsize {filter: drop-shadow(0px 8px 10px #f5a33e) invert(48%) sepia(19%) saturate(2476%) hue-rotate(200deg) brightness(100%) contrast(50%)}
